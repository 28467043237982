<template >
  <div class="mt-4">
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{ $t("message.new_m", { m: $t("message.product"), }) }}
          </p>
          <div class="d-flex">
            <div class="mr-2 mt-1">
              <el-button
                @click="childProductListOpen()" 
                type="primary" 
                size="medium">
                  {{ $t('message.upkeep') }}
              </el-button>
            </div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  :label="$t('message.name')"
                  class="label_mini"
                  prop="name"
                >
                  <el-input
                    :placeholder="$t('message.name')"
                    v-model="form.name"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-tabs type="card">
                  <el-tab-pane v-for="(value, name, index) in form.names" :key="'lang'+index" :label="$t('message.'+name)">
                    <el-form-item
                      class="label_mini"
                    >
                      <el-input
                        :placeholder="$t('message.name')"
                        v-model="form.names[name]"
                        size="medium"
                      ></el-input>
                      <ckeditor
                        :editor="editor"
                        v-model="form.descriptions[name]"
                        :config="editorConfig"
                      ></ckeditor>
                    </el-form-item>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item
                    :label="$t('message.buy_price')"
                    class="label_mini"
                    prop="buy_price"
                  >
                    <el-input
                      type="number"
                      :placeholder="$t('message.buy_price')"
                      v-model="form.buy_price"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item
                    :label="$t('message.buy_price_currency_id')"
                    class="label_mini"
                    prop="buy_price_currency_id"
                  >
                    <select-currency
                      ref="buyPriceCurrency"
                      :size="'medium'"
                      :placeholder="columns.buy_price_currency_id.title"
                      :id="form.buy_price_currency_id"
                      v-model="form.buy_price_currency_id"
                    >
                    </select-currency>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item
                    :label="$t('message.selling_price')"
                    class="label_mini"
                    prop="selling_price"
                  >
                    <el-input
                      type="number"
                      :placeholder="$t('message.selling_price')"
                      v-model="form.selling_price"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                  <el-form-item
                    :label="$t('message.selling_price_currency_id')"
                    class="label_mini"
                    prop="selling_price_currency_id"
                  >
                    <select-currency
                      ref="sellingPriceCurrency"
                      :size="'medium'"
                      :placeholder="columns.selling_price_currency_id.title"
                      :id="form.selling_price_currency_id"
                      v-model="form.selling_price_currency_id"
                    >
                    </select-currency>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                  <el-form-item
                    :label="$t('message.code')"
                    class="label_mini"
                    prop="code"
                  >
                    <el-input
                      :placeholder="$t('message.code')"
                      v-model="form.code"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                  <el-form-item
                    :label="$t('message.measurement_id')"
                    class="label_mini"
                    prop="measurement_id"
                  >
                    <select-measurement
                      :size="'medium'"
                      :placeholder="columns.measurement_id.title"
                      :id="form.measurement_id"
                      v-model="form.measurement_id"
                    >
                    </select-measurement>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                  <el-form-item
                    :label="$t('message.packages')"
                    class="label_mini"
                    prop="packages"
                  >
                    <select-packages
                      ref="packages"
                      v-model="form.packages"
                      :package_ids="form.packages"
                      :size="'medium'" 
                      :placeholder="$t('message.packages')"
                    >
                    </select-packages>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                  <el-form-item
                    :label="$t('message.product_type')"
                    class="label_mini"
                    prop="production"
                  >
                    <el-switch
                      v-model="form.production"
                      active-value="1"
                      :active-text="$t('message.productionable')"
                      inactive-value="0"
                      :inactive-text="$t('message.ready')">
                    </el-switch>
                  </el-form-item>
                </el-col>

					 <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                  <el-form-item
                    :label="$t('message.vendor_code')"
                    class="label_mini"
                  >
                     <el-input
								:placeholder="$t('message.vendor_code')"
								v-model="form.vendor_code"
								size="medium"
                    >
						  </el-input>
                  </el-form-item>
                </el-col>

                <!-- end-col -->
                <el-col :span="24">
                  <el-form-item
                    :label="$t('message.categories')"
                    prop="categories"
                    class="label_mini"
                  >
                    <el-tree
                        ref="category"
                        :data="updatedCategories"
                        show-checkbox
                        node-key="id"
                        :default-checked-keys="[]"
                        :props="defaultProps">
                      </el-tree> 
                  </el-form-item>
                </el-col>

                <el-col ::xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    
                    :label="columns.is_default.title"
                    class="label_mini"
                  >
                      <el-switch v-model="form.is_default" @change="defaultChange"></el-switch>
                  </el-form-item>
                </el-col>

                <el-col ::xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :label="columns.product_model_id.title"
                    class="label_mini"
                    prop="product_model_id"
                    v-if="!form.is_default"
                  >
                    <select-product-model
                      :size="'medium'"
                      :placeholder="columns.product_model_id.title"
                      :id="form.product_model_id"
                      v-model="form.product_model_id"
                    >
                    </select-product-model>
                  </el-form-item>
                </el-col>        
                
                <el-col :span="24">
                    <el-upload class="upload-demo w-100" accept="image/jpeg, image/png" action="/" :limit="3" :on-change="updateImageList" :on-remove="handleRemove" list-type="picture-card" :file-list="imageList" :auto-upload="false">
                        <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                </el-col>

            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
      <el-drawer
        :class="mode ? 'l-modal-style' : 'd-modal-style'"
        :with-header="false"
        :visible.sync="drawerChildProduct"
        :append-to-body="true"
        ref="drawerChildProduct"
        size="100%"
        @opened="drawerOpened('drawerChildProductChild')"
        @closed="drawerClosed('drawerChildProductChild')"
      >
        <div>
          <child-products
            :deal_id="deal_id"
            :filial_id="filial_id"
            :selectedRow="selectedRow"
            :disable_product_quantity="true"
            @setChildProducts="setChildProducts"
            ref="drawerChildProductChild"
            drawer="drawerChildProduct"
          >
          </child-products>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectCurrency from "@/components/inventory/select-currency";
import selectColor from "@/components/inventory/select-color";
import selectProductModel from "@/components/inventory/select-product-model";
import selectMeasurement from "@/components/inventory/select-measurement";
import selectPackages from "@/components/multiSelects/multi-select-packages";
import child_list from "@/utils/mixins/child_list";
import childProducts from "@/views/deals/components/dealProduct/deal-product-childs";
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import _ from 'lodash';

export default {
  mixins: [drawer, child_list],
  components: {
    selectCurrency,
    selectMeasurement,
    selectProductModel,
    selectColor,
    selectPackages,
    childProducts
  },
  data() {
    return {
      loadingButton: false,
      form: {},
      imageList: [],
      updateImage: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      updatedCategories: [],
      drawerChildProduct: false,
      selectedRow: {},
      filial_id: null,
      deal_id: null,
      editor: Editor,
      editorConfig: {}
    };
  },
//   created() {
//       if (this.model != null) {           
//         this.form = JSON.parse(JSON.stringify(this.model));         
//       }
//   },
  computed: {
    ...mapGetters({
      rules: "products/rules",
      model: "products/model",
      columns: "products/columns",
      categories: "categories/treeInventory",
      currencies: "currency/inventory",
      mode: "MODE",
    }),
  },
  watch: {
    "form.selling_price_currency_id": {
      handler: async function(newVal, oldVal) {
        if(newVal){
          let currency = this.currencies.find(el => el.id === newVal);
          if(currency){
            this.form.currency_rate = currency.rate;
          }
        }else {
          let currency = this.currencies.find((currency) => currency.active);
          if(currency){
            this.form.currency_rate = currency.rate;
          }
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    ...mapActions({
      save: "products/store",
      empty: "products/empty",
      updateInventory: "categories/treeInventory",
      updateFeeInventory: "dealProductAdditionalFeeType/index", 
    }),
    submit(close = true) {
      if(this.$refs.category){
        this.form.categories = this.$refs.category.getCheckedKeys();
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let formData = new FormData();

          for (const key in this.form) {
              if (key != 'is_default' && key != 'names' && key != 'descriptions' && key != 'child_products' && key != 'feeList') {
                  if (this.form[key]) {
                      formData.append(key, this.form[key]);
                  } else {
                      formData.append(key, '');
                  }
              } else if (key == 'is_default') {
                  formData.append(key, this.form[key]);
              } else if (key == 'names'|| key == 'descriptions' || key == 'child_products' || key == 'feeList') {
                  formData.append(key, JSON.stringify(this.form[key]));
              }
          }

          if (_.size(this.updateImage) > 0) {
              for (const key in this.updateImage) {
                  if (this.updateImage.hasOwnProperty(key)) {
                      const element = this.updateImage[key];
                      formData.append(`images[${key}]`, element);
                  }
              }
          }
          this.form.images = formData;
          
          
          this.loadingButton = true;
          this.save(formData)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },

    updateImageList(file) {
        this.updateImage.push(file.raw);
    },
    handleRemove(file) {
        if (file.id) {
            this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                this.$alert(res);
            }).catch(err => {});
        } else {
            for (var i = 0; i < _.size(this.updateImage); i++) {
                if (this.updateImage[i].name === file.name) {
                    this.updateImage.splice(i, 1);
                }
            }

        }
    },

    defaultChange(e){
      this.form.is_default = e;
    },
    afterOpen() {
		this.form = JSON.parse(JSON.stringify(this.model));
      this.updateFeeInventory();
      this.updateInventory().then(res => {
        this.updatedCategories = JSON.parse(JSON.stringify(this.categories));
      });
      setTimeout(() => {
        this.$refs.buyPriceCurrency.selectedForCreate();
        this.$refs.sellingPriceCurrency.selectedForCreate();  
      }, 300);
    },
    childProductListOpen(){
      this.selectedRow = JSON.parse(JSON.stringify(this.form));
      this.drawerChildProduct = true;
    },
    setChildProducts(item){
      console.log(item, '<-item create set child product form->', this.form);

      this.$set(this.form, 'calculation_type_id', item.calculation_type_id);
      this.$set(this.form, 'selling_price', parseFloat(item.last_price));
      this.$set(this.form, 'profit', parseFloat(item.profit));
      this.$set(this.form, 'child_products', item.products);
      this.$set(this.form, 'feeList', item.feeList);

      
      this.selectedRow = {};
    },
    parent() {
        return this.$parent.$parent
    },
    reloadForm() {
        if (this.model != null) {
            this.form = JSON.parse(JSON.stringify(this.model));
        }
    },
    afterLeave(){
      this.empty();
      this.imageList = []
    },
    onEditorBlur(editor) {
      console.log('Редактор потерял фокус', editor)
    },
    onEditorFocus(editor) {
      console.log('Редактор в фокусе', editor)
    },
    onEditorReady(editor) {
      console.log('Редактор готов к использованию', editor)
    }
  },
};
</script>
<style lang="scss" scoped>
.ql-toolbar .ql-picker-options {
    z-index: 9999 !important;
    position: absolute !important;
}
.ql-toolbar {
    position: relative !important;
}
</style>
